import { gql, Provider } from "urql";
import { GlobalStyles } from "@wpf-admin-ui/styles/global";
import { createGraphqlClient, createGraphqlGatewayClient } from "@wpf-admin-ui/utils";
import App, { type AppContext } from "next/app";
import { type AppProps } from "next/dist/shared/lib/router/router";
import Head from "next/head";
import { CurrentUserContext, GatewayClientContext } from "@wpf-admin-ui/contexts";
import { fetchCurrentUser } from "@wpf-admin-ui/utils/auth/fetchCurrentUser";
import { Box, ChakraProvider, Flex } from "@chakra-ui/react";
import { theme } from "@wpf-admin-ui/styles/themes/extend";
import { NotFound } from "@wpf-admin-ui/components/essentials/NotFound";
import React from "react";
import { AuthGuard } from "@wpf-admin-ui/components/auth";
import { useRequestSignIn } from "@wpf-admin-ui/hooks";
import { type FeatureFlag } from "@wpf-admin-ui/gql/generated/graphql";
import "~/node_modules/@sheinc/shelikes-design-system/lib/style.css";
import { ErrorProvider } from "@wpf-admin-ui/contexts/ErrorContext";
import { SideMenu } from "~/src/components/fragments/side-menu";
import { type CurrentUser } from "@wpf-admin-ui/contexts/currentUser";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

gql(`
query currentUser {
  currentUser {
    id
    name
    profilePhotoUrl
    isWorksAdminUser
    isWorksStaffUser
    isWorksShemateUser
  }
  enabledFeatures {
    enabled
    key
  }
}
`);

const queryClient = new QueryClient();

type Props = Awaited<ReturnType<typeof MyApp.getInitialProps>> & AppProps;

function MyApp(props: Props): JSX.Element {
  const { Component, pageProps, currentUser, token, isForbidden, enabledFeatures } = props;
  const client = createGraphqlClient(token);
  const gatewayClient = createGraphqlGatewayClient(token);
  useRequestSignIn(currentUser, isForbidden);
  const currentUserContext = {
    ...(currentUser as CurrentUser),
    enabledFeatures: new Set(enabledFeatures.map((v: FeatureFlag) => v.key)),
  };

  return (
    <CurrentUserContext.Provider value={currentUserContext}>
      <Head>
        <title>Admin - SHEcreators</title>
        <meta name="robots" content="noindex,nofollow" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <Provider value={client}>
          <GatewayClientContext.Provider value={{ client: gatewayClient }}>
            <ChakraProvider theme={theme}>
              <GlobalStyles />
              {isForbidden ? (
                <NotFound />
              ) : (
                <ErrorProvider>
                  <AuthGuard>
                    <Flex>
                      <SideMenu />
                      <Box flex={1}>
                        <Box>
                          <Component {...pageProps} />
                        </Box>
                      </Box>
                    </Flex>
                  </AuthGuard>
                </ErrorProvider>
              )}
            </ChakraProvider>
          </GatewayClientContext.Provider>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </CurrentUserContext.Provider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const [token, currentUser, isForbidden, enabledFeatures] = await fetchCurrentUser(appContext.ctx);
  return { ...appProps, currentUser, token, isForbidden, enabledFeatures };
};

export default MyApp;
